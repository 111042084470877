import WeatherIcon from "../components/weatherIcon"
import Card from "./card"
import React from "react"
import HashLoader from "react-spinners/HashLoader"

export default function FiveDaysForecast({ isLoading, isError, data }) {
  if (!data) return null

  return (
    <Card title="5-Day Weather Forecast" color="primary">
      <div>
        {isLoading && (
          <div className="flex items-center justify-center h-20 text-base text-gray-600">
            <HashLoader size={24} color={"#1987C2"} loading={isLoading} />
          </div>
        )}
        {isError && (
          <div className="flex items-center justify-center h-20 px-5 text-base text-gray-600">
            Error Fetching Data
          </div>
        )}

        <div className="grid grid-cols-3 gap-4 py-5 bg-white divide-x-2 md:gap-0 md:grid-cols-6">
          {data.map((day) => {
            return (
              <div key={day.ms} className={`text-center font-semibold text-sm`}>
                <div>
                  <div className="pb-1 mx-1 font-bold tracking-tight sm:tracking-wider">
                    {day.date.toLocaleString("default", {
                      weekday: "long",
                    })}
                  </div>
                  <small className="text-xs text-gray-700 sm:inline">
                    {day.date.toLocaleDateString("en-US")}
                  </small>
                </div>

                <div className="flex justify-center mt-2">
                  <WeatherIcon
                    text={day.weather}
                    size={`w-6 h-6`}
                  ></WeatherIcon>
                </div>

                <div className="flex justify-between w-2/4 mx-auto mt-2">
                  <span className="text-red-800">
                    <span className="sr-only">High Temperature: </span>
                    {day.maxt === "M" ? "N/A" : day.maxt.toFixed(0)}
                  </span>
                  <span className="text-secondary-800">
                    <span className="sr-only">Low Temperature: </span>
                    {day.mint === "M" ? "N/A" : day.mint.toFixed(0)}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Card>
  )
}
