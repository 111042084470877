import { Link } from 'gatsby'
import React from 'react'

const NewsCard = ({
  icon = false,
  title,
  children,
  btnLabel,
  to = '/',
  color,
  favoriteStations,
  map = false,
  weatherVariables = false,
  setIsModalOpen,
  isAuthenticated,
  station = null,
}) => {
  const bg = color === 'primary' ? `bg-primary-700` : `bg-secondary-700`
  return (
    <div className="overflow-hidden text-left rounded-lg shadow-lg">
      {/* Header */}
      <div className={`flex items-center py-3 px-3 sm:px-6 ${bg}`}>
        {icon && (
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            className="mr-1 text-orange-400 star w-7 h-7"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
        )}
        <span
          className={`text-white text-left text-xs sm:text-base md:text-lg mr-auto max-w-8 font-bold truncate py-1 flex items-center`}
        >
          <h2
            className={`font-body text-base ${
              map ? `lg:text-lg` : `lg:text-xl`
            } truncate`}
          >
            {title}
          </h2>
          {favoriteStations && favoriteStations.length !== 0 && (
            <span className="flex items-center justify-center ml-3 border rounded-full w-7 h-7">
              <span className="text-xs font-extrabold">
                {favoriteStations.length}
              </span>
            </span>
          )}
        </span>

        {btnLabel && (
          <Link
            to={to}
            className={`btn ${
              color === 'primary' ? `btn-primary` : `btn-secondary`
            }`}
            aria-label={title}
          >
            {btnLabel}{' '}
            <span className="ml-2 sm:ml-3">
              <svg
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 arrow-narrow-right"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </Link>
        )}

        {isAuthenticated && weatherVariables && station !== null && (
          <button
            onClick={() => setIsModalOpen(true)}
            className="hidden sm:inline-flex items-center px-2.5 py-2 border border-transparent text-xs leading-4 font-semibold rounded text-white bg-primary-800 hover:bg-primary-700 hover:border-white focus:outline-none focus:border-white focus:shadow-outline-primary  active:bg-primary-700 active:border-white transition ease-in-out duration-150 ml-2"
          >
            <span>Edit Weather Overview</span>
            {/* <span className="sm:hidden">Edit</span> */}
          </button>
        )}
      </div>

      {children}
    </div>
  )
}

export default NewsCard
