import Cloudy from "../svg/wi-cloudy.svg"
import DayCloudy from "../svg/wi-day-cloudy.svg"
import DayRainMix from "../svg/wi-day-rain-mix.svg"
import DaySunny from "../svg/wi-day-sunny.svg"
// Night
import NightClear from "../svg/wi-night-clear.svg"
import NightCloudy from "../svg/wi-night-cloudy.svg"
import NightRainMix from "../svg/wi-night-rain.svg"
import Rain from "../svg/wi-rain.svg"
import React from "react"

// five options:  clear, mixed, cloudy, rain-mix, rain
export default function WeatherIcon({ text, size, hour = 12 }) {
  const isDay = hour >= 5 && hour <= 18
  return (
    <div>
      {/* Day */}
      {text === "clear" && isDay && (
        <DaySunny alt={text} className={size}></DaySunny>
      )}
      {text === "mixed" && isDay && (
        <DayCloudy alt={text} className={size}></DayCloudy>
      )}
      {text === "rain-mix" && isDay && (
        <DayRainMix alt={text} className={size}></DayRainMix>
      )}

      {/* Both */}
      {text === "cloudy" && <Cloudy alt={text} className={size}></Cloudy>}
      {text === "rain" && <Rain alt={text} className={size}></Rain>}

      {/* Night */}
      {text === "clear" && !isDay && (
        <NightClear alt={text} className={size}></NightClear>
      )}
      {text === "mixed" && !isDay && (
        <NightCloudy alt={text} className={size}></NightCloudy>
      )}
      {text === "rain-mix" && !isDay && (
        <NightRainMix alt={text} className={size}></NightRainMix>
      )}
    </div>
  )
}
