import GlobalStateContext from "../context/globalStateContext"
import React from "react"

export default function ClosestStation({ closestStations }) {
  const { counter } = React.useContext(GlobalStateContext)

  return (
    <>
      {counter === 0 && closestStations.length !== 0 && (
        <div className="flex flex-col px-2 mt-4 font-semibold text-gray-800">
          <p className="flex items-center justify-center text-lg md:text-xl">
            NEWA chose
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6 text-orange-400 star"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            {closestStations[0].stn.name}, {closestStations[0].stn.state}{" "}
            station for you
          </p>
        </div>
      )}
    </>
  )
}
