import { getDayOfYear, isToday, isYesterday } from 'date-fns'
import React from 'react'
import HashLoader from 'react-spinners/HashLoader'
import weatherStationOverview from '../../static/weather-station-overview.json'
import WeatherIcon from '../components/weatherIcon'
import GlobalStateContext from '../context/globalStateContext'
import { useAuth0 } from '../context/react-auth0-spa'
import {
  calculateCustomGdd,
  calculateGdd,
  determineWeatherIconString,
  median,
  simpleAve,
  wdirInCompassUnit,
} from '../utils/helpers'
import Card from './card'

const currentDayOfYear = getDayOfYear(new Date())

function adjustMonthFromDB(month) {
  const currentMonth = new Date().getMonth() + 1
  if (month === 'January 1' && currentMonth >= 1) return 'January 1'
  if (month === 'January 1' && currentMonth >= 3) return 'March 1'
  if (month === 'January 1' && currentMonth >= 4) return 'April 1'
  if (month === 'January 1' && currentMonth >= 5) return 'May 1'
  return 'January 1'
}

export default function WeatherStationOverview({
  station,
  isLoading,
  isError,
  data,
  setIsModalOpen,
}) {
  const { isAuthenticated, user } = useAuth0()
  const { isSearch } = React.useContext(GlobalStateContext)
  const [weatherVariables, setWeatherVariables] = React.useState(() =>
    weatherStationOverview.weatherVariables.filter((d) => d.isChecked),
  )

  let lastHourData
  let currentHour
  let asOfHour
  let currentHourDisplay = ''
  let currentHourTemp = ''
  let yesterdayMinTemp = ''
  let yesterdayMaxTemp = ''
  let yesterdayPrcpTot = ''
  let wdirInCompass = ''
  let weatherIcon = ''
  let defaultGdd = 'N/A'
  let customGdd = 'N/A'
  let currentDateMinT
  let currentDateMaxT
  let currentDatePrcpTot

  if (data) {
    const { hourlyData, hourlyFcstData, dailyData } = data
    lastHourData = hourlyData.slice(-1)[0]
    currentHour = lastHourData.date.getHours()

    currentHourDisplay = `At ${lastHourData.date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hours12: true,
    })} today`

    asOfHour = `as of ${lastHourData.date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hours12: true,
    })}`

    // Current Hour Temperature
    if (lastHourData.temp !== 'M') {
      currentHourTemp = `${lastHourData.temp.toFixed(0)} ˚F`
    }

    // current date data
    const dailyCurrentDate = dailyData.filter((d) => isToday(d.date))[0]

    currentDateMinT = Math.min(...dailyCurrentDate.temp.slice(0, currentHour))
    currentDateMaxT = Math.max(...dailyCurrentDate.temp.slice(0, currentHour))
    const currentDatePrcp = dailyCurrentDate.prcp
      .slice(0, currentHour)
      .filter((d) => typeof d === 'number')
    currentDatePrcpTot = currentDatePrcp.reduce((acc, curr) => acc + curr, 0)

    // Yesterday daily data
    const dailyYesterday = dailyData.filter((d) => isYesterday(d.date))[0]

    // Yesterday Min and Max Temperatures
    if (dailyYesterday) {
      if (dailyYesterday.mint !== 'M' && dailyYesterday.maxt !== 'M') {
        yesterdayMinTemp = `${dailyYesterday.mint.toFixed(0)} ˚F`
        yesterdayMaxTemp = `${dailyYesterday.maxt.toFixed(0)} ˚F`
      }
    }

    // Yesterday Rainfall
    if (dailyYesterday) {
      yesterdayPrcpTot = dailyYesterday.prcpCumulative
    }

    // Convert wind speed unit from degree to compass ////////////
    let wspd = lastHourData.wspd
    let wdir = lastHourData.wdir
    if (wspd !== 'M' && wdir !== 'M') {
      wdirInCompass = wdirInCompassUnit(wspd, wdir)
    }

    // Determine current hour weather icon ////////////////////////
    if (!isLoading && hourlyFcstData) {
      const todayObservedData = hourlyData.slice(-currentHour - 1)
      const todayForecastData = hourlyFcstData.slice(0, 23 - currentHour)
      const today = [...todayObservedData, ...todayForecastData]

      const pop12Max = Math.max(
        ...today
          .filter((d) => d !== 'M')
          .filter((d) => d.pop12)
          .map((d) => d.pop12),
      )
      const tskyMedian = median(
        today
          .filter((d) => d !== 'M')
          .filter((d) => d.tsky)
          .map((d) => d.tsky),
      )
      weatherIcon = determineWeatherIconString(
        pop12Max,
        tskyMedian,
        currentHour,
      )
    }

    // Calculate Default GDD - (50˚F, Jan 1, max/min)
    const gdd50 = calculateGdd(
      dailyData,
      50, // 50˚F
      0, // Jan 1
      simpleAve, // max/min
    ).find((d) => d.dayOfYear === currentDayOfYear)
    if (gdd50) defaultGdd = gdd50.gdd

    // Calculate Custom GDD -------------------------
    if (
      user &&
      Object.keys(user).includes('customDegreeDay') &&
      user.customDegreeDay.isChecked
    ) {
      const { accumulationStartDate, baseTemperature, formula } =
        user.customDegreeDay

      const gdd2 = calculateCustomGdd(
        dailyData,
        baseTemperature.value,
        accumulationStartDate.value,
        formula.value,
      )

      if (typeof gdd2 === 'number') customGdd = gdd2
    }
  }

  React.useEffect(() => {
    if (!isAuthenticated || station === null) return

    if (user && Object.keys(user).includes('weatherVariables')) {
      let stationParams = []
      const elems = Object.keys(station.elems)
      let extra = []
      if (station.extraelems.length !== 0) {
        extra = station.extraelems.map((d) => d.name)
      }
      stationParams = [...elems, ...extra]
      const ss = user.weatherVariables.filter((d) =>
        stationParams.includes(d.param),
      )
      setWeatherVariables(ss)
    }
  }, [user, isAuthenticated, station])

  return (
    <Card
      title={`${station ? station.name : 'Weather Station'} Overview`}
      color="primary"
      elevation={station ? station.elev : null}
      weatherVariables={true}
      setIsModalOpen={setIsModalOpen}
      isAuthenticated={isAuthenticated}
      station={station}
    >
      {isLoading && station !== null && (
        <div className="flex items-center justify-center h-20 text-base text-gray-600">
          <HashLoader size={24} color={'#1987C2'} loading={isLoading} />
        </div>
      )}

      {isSearch === false && station === null && (
        <div className="flex flex-col items-center justify-center h-20 px-5 my-2 text-base text-gray-600">
          <div className="mb-3 text-lg font-semibold text-center">
            Select a station
          </div>
        </div>
      )}

      {isError && (
        <div className="flex items-center justify-center h-20 px-5 text-base text-gray-600">
          Error Fetching Data
        </div>
      )}

      {!data && !isLoading && station !== null && (
        <div className="flex items-center justify-center h-20 text-base text-gray-600">
          No Data
        </div>
      )}

      {!isLoading && !isError && data && station !== null && (
        <div>
          {!isAuthenticated && (
            <div className="p-3 text-sm font-bold text-center bg-gray-100 sm:px-6">
              Courtesy of
              {station.affiliationUrl.length !== 0 ? (
                <a
                  href={station.affiliationUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 underline"
                >
                  {station.affiliation}
                </a>
              ) : (
                <span> {station.affiliation}</span>
              )}
            </div>
          )}

          <div>
            <div className="p-3 mt-5 text-xs sm:text-xs md:text-sm">
              <div className="flex flex-col sm:flex-row">
                <div className="flex-auto mx-1 mb-4 sm:mb-6 lg:pr-0">
                  <div className="w-48 p-2 mx-auto text-xs font-extrabold tracking-widest text-center text-white rounded-full bg-primary-700">
                    {currentHourDisplay}
                  </div>

                  {/* daily temperature */}
                  <div className="flex items-center justify-center mt-2 font-extrabold md:mt-6">
                    <WeatherIcon
                      hour={currentHour}
                      text={weatherIcon}
                      size="w-14 h-14"
                    ></WeatherIcon>
                    <span className="ml-3 text-3xl md:text-4xl">
                      {currentHourTemp}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-auto font-semibold">
                  {/* Default Degree Day */}
                  <div
                    className={`flex justify-between items-center bg-gray-50 mb-1 py-1 px-1 rounded-md text-xs sm:text-xs md:text-sm`}
                  >
                    <span className="block ml-2 text-gray-900">
                      <span className="mt-1 mr-auto text-xs tracking-wide">
                        Base 50˚F Degree Days since January 1
                      </span>
                    </span>

                    <span className="italic text-gray-700">{defaultGdd}</span>
                  </div>

                  {/* Custom Degree Day */}
                  {user &&
                    Object.keys(user).includes('customDegreeDay') &&
                    user.customDegreeDay.isChecked && (
                      <div
                        className={`flex justify-between items-center bg-gray-50 my-1 py-1 px-1 rounded-md text-xs sm:text-xs md:text-sm`}
                      >
                        <span className="block ml-2 text-gray-900">
                          <span className="mt-1 mr-auto text-xs tracking-wide">
                            Base {user.customDegreeDay.baseTemperature.value}{' '}
                            {user.customDegreeDay.formula.value ===
                              'Baskerville Emin' && '(BE)'}{' '}
                            Degree Days since{' '}
                            {adjustMonthFromDB(
                              user.customDegreeDay.accumulationStartDate.value,
                            )}
                          </span>
                        </span>

                        <span className="italic text-gray-700">
                          {customGdd}
                        </span>
                      </div>
                    )}

                  {weatherVariables.map((d) => {
                    let val =
                      lastHourData[d.param] === 'M'
                        ? 'N/A'
                        : lastHourData[d.param]
                    if (d.param === 'wdir') val = wdirInCompass
                    return (
                      <div
                        key={d.id}
                        className="flex items-center justify-between px-1 py-1 my-1 text-xs rounded-md bg-gray-50 md:text-sm"
                      >
                        <span className="flex items-center ">
                          <span className="block ml-2 leading-5 text-gray-900">
                            <span className="mt-1 mr-auto tracking-wide">
                              {d.name}
                            </span>
                          </span>
                        </span>
                        <span className="italic text-gray-900">
                          {val}
                          {val !== 'N/A' && (
                            <span className="ml-1">{d.unit}</span>
                          )}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="relative mt-8 text-xs bg-gray-50 lg:text-sm">
              <span className="absolute px-3 py-2 ml-10 -mt-5 text-xs font-extrabold tracking-widest text-center text-white rounded-full sm:px-5 bg-primary-700">
                Yesterday
              </span>
              <div className="flex items-center justify-around p-3 pt-6 -mx-2 text-xs lg:text-xs">
                <div className={`w-1/3 text-center font-semibold border-r`}>
                  <span className="hidden sm:inline">Precipitation:</span>{' '}
                  <span className=" sm:hidden">Prec:</span> {yesterdayPrcpTot}{' '}
                  in
                </div>

                <div className={`w-1/3 text-center font-semibold border-r`}>
                  <span>High Temp:</span>
                  <span className="ml-2 sm:ml-1">{yesterdayMaxTemp}</span>
                </div>

                <div className={`w-1/3 text-center font-semibold`}>
                  <span>Low Temp:</span>
                  <span className="ml-2 sm:ml-1">{yesterdayMinTemp}</span>
                </div>
              </div>
            </div>

            <div className="relative mt-8 text-xs bg-gray-50 lg:text-sm">
              <span className="absolute px-3 py-2 ml-10 -mt-5 text-xs font-extrabold tracking-widest text-center text-white rounded-full sm:px-5 bg-primary-700">
                Today {asOfHour}
              </span>
              <div className="flex items-center justify-around p-3 pt-6 -mx-2 text-xs lg:text-xs">
                <div className={`w-1/3 text-center font-semibold border-r`}>
                  <span className="hidden sm:inline">Precipitation:</span>{' '}
                  <span className=" sm:hidden">Prec:</span>{' '}
                  {currentDatePrcpTot === 0 ? 0 : currentDatePrcpTot.toFixed(2)}{' '}
                  in
                </div>

                <div className={`w-1/3 text-center font-semibold border-r`}>
                  <span>High Temp:</span>
                  <span className="ml-2 sm:ml-1">
                    {currentDateMaxT.toFixed(0)} ˚F
                  </span>
                </div>

                <div className={`w-1/3 text-center font-semibold`}>
                  <span>Low Temp:</span>
                  <span className="ml-2 sm:ml-1">
                    {currentDateMinT.toFixed(0)}˚F
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}
